/* esto es el reset */

* {
  outline: none;
}

body{
    height: 100%;
    position: absolute;
    width: 100%;
}

ul {
  list-style-type: none;
  padding-left: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
}

/*esto es el framework del layout */

.full-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white
}

.fill-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white
}

.padding  {
  padding: 15px;
}

.flex-row {
    display: flex;
    flex-direction: row; 
}

.flex-column {
    display: flex;
    flex-direction: column; 
}

.flex {
    flex-grow: 1
}

.flex-90 {
  flex-basis: 90%
}

.flex-80 {
    flex-basis: 80%
}

.flex-10 {
    flex-basis: 10%
}

.flex-center-content {
  justify-content: center;
  align-items: center;
}

.scroll-y {
  overflow-y: auto;  
}

.header {
  abackground-color: cyan;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  font-size: 20px;
  min-height: 60px;
}

.header > *:nth-child(2) {
  flex-grow: 1;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.summary {
  abackground-color: pink;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;

}
.summary-item {
  border: 1px solid grey;
  flex-grow: 1;
  text-align: center;
}

.summary-item-header {
  abackground-color: yellow;
}


.summary-item-content {
  font-weight: bold;
}

.detail {
  aborder: 2px solid green;
  amargin: 8px;
  padding: 8px;
  overflow-y: auto;
  flex-grow: 1;

}
.detail-item {
  border: 1px solid grey;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

.detail-item .type {
  font-weight: bold;
  display: inline-block;
  width: 100%;
}

.detail-item .elapsed-time {
  position: absolute;
  font-size: 20px;
  right: 8px;
}

.detail-item .description {
  width: 100%;
  margin-bottom: 5px;
}

.detail-item .started-on,
.detail-item .ended-on {
  width: 50%;
  font-size: 10px;
}

.detail-item .started-on {
  text-align: left;
}

.detail-item .ended-on {
  text-align: right;
}


/*desde aqui son elementos genericos de la aplicacion */

.button {
  /* aspecto */
  background-color: transparent;
  border: none;
  background-size: 100% auto;
  
  /* tamaño */
  width: 30px;
  height: 30px;
}

.floating-button {
  position: absolute;
  border: none;

  box-shadow: 2px 4px 5px 0px rgba(0,0,0,0.54);


  border-radius: 50%;
  width: 48px;
  height: 48px;

  left: 50%;
  margin-left: -24px;
  bottom: 15px;

}

.button-add {
  background: url('./img/add.svg') no-repeat;
  background-color: orange;
  background-size: 100% auto;
}

.button-close {
  background: url('./img/close.svg') no-repeat;
  background-size: 100% auto;
}

.button-filter {
  background: url('./img/search.svg') no-repeat;
  background-size: 100% auto;
}

.player-button {
  /* aspecto */
  background-color: transparent;
  border: none;
  background-size: 100% auto;
  
  /* tamaño */
  width: 30px;
  height: 30px;
}

.player-button.play {
  background: url('./img/play.svg') no-repeat;
  background-size: 100% auto;
 }
.player-button.pause {
  background: url('./img/pause.svg') no-repeat;
  background-size: 100% auto;
}

/*especificos de cada pantalla */

.task-list {
  overflow-y: auto;  
}

.task-item {
  position: relative;
  
  border: 1px solid grey;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  min-height: 100px;
}

.task-item .name {
  /* aspecto */
  font-weight: bold;

  /* fluidez del texto */
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.task-item .player-button {
  /* tamaño */
  width: 60px;
  height: 60px;
  
  /* posicionamiento */
  position: absolute;
  right: 15px;
  bottom: 15px;
}



.task-item .elapsed.running,
.task-item .total.not-running {
  /* aspecto */
  font-size: 32px;

  /* posicionamiento */
  position: absolute;
  left: 15px;
  bottom: 10px;
}


.task-item .total.running {
  /* aspecto */
  font-size: 16px;

  /* posicionamiento */
  position: absolute;
  right: 90px;
  bottom: 15px;
}

.App {
  text-align: center;
  background-color: green
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.matias {
  border: 5px solid red
}

.img-center {
    margin: auto auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
